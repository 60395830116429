import http from "@/app/core/config/axios.config";

export default class PagosService {
  /* PARAMETROS --------------------------------------------------------------------------------------------------------------- */

  static getParametrosPago() {
    return http.get("/pagos/Parametros");
  }

  /* TRANSACCION -------------------------------------------------------------------------------------------------------------- */

  static realizarPago(entry) {
    return http.post("pagos/Transaccion/Iniciar", entry);
  }

  static getEstadoTransaccion(entry) {
    return http.get(`/pagos/Transaccion/ObtenerEstadoTransaccion?Guid=${entry}`);
  }

  /* TARJETA RECAUDO ---------------------------------------------------------------------------------------------------------- */

  static getTarjetaRecaudo(entry) {
    return http.post("/tarjetarecaudo", entry);
  }
}
